<template>
  <div
    class="d-flex h-100 flex-fill flex-column"
  >
    <Logo class="agriplus-logo mt-2 mb-5" />
    <router-view class="flex-fill" />
    <Footer class="mt-2" />
  </div>
</template>

<script>
import Logo from '@core/layouts/components/Logo.vue'
import Footer from '@core/layouts/components/Footer.vue'

export default {
  components: {
    Logo,
    Footer,
  },
  data() {
    return {}
  },
  computed: {},
  mounted() {
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.agriplus-logo {
  width: 160px;
}
</style>
