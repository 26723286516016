<template>
  <img
    :src="logoSVG"
    :alt="$config.brand.name"
  >
</template>

<script>
import Logo from '../../../assets/images/logo/agriplus-logo.svg'

export default {
  data() {
    return {
      logoSVG: Logo,
    }
  },
}
</script>
