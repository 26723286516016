<template>
  <span class="version">
    {{ config.version }}
  </span>
</template>

<script>
import config from '@/config.json'

export default {
  computed: {
    config() {
      return config
    },
  },
}
</script>

<style scoped lang="scss">
</style>
