<template>
  <footer class="font-weight-light pb-75">
    <span class="mb-50 d-block">{{ $t('therms.app-version') }} <version /></span>
    <span>Copyright © {{ $moment().year() }} <a
      target="_blank"
      :href="$config.brand.url"
    >{{ $config.brand.name }}</a>, {{ $t('footer.copyright-all-right-reserved') }}</span>
  </footer>
</template>

<script>
import AppVersion from '@core/components/app-version/AppVersion.vue'

export default {
  components: {
    version: AppVersion,
  },
}
</script>

<style lang="scss" scoped>
footer {
  font-size: 0.9rem;
}
</style>
